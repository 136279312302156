import { Injectable, inject } from '@angular/core';

import { BooktechAppService, MiscUtil } from '@btlib-core';

import { BergensportsreiserService } from './bergensportsreiser.service';

export const APP_DATAID = {
  ORDER_OVERVIEW_DATA: "ORDER_OVERVIEW_DATA",
  ORDER_LAST_BID: "ORDER_LAST_BID",
  
}


@Injectable({
  providedIn: 'root'
})
export class AppService {
  name = "AppService"
  public bas = inject(BooktechAppService);

  
  bss:BergensportsreiserService|undefined;

  data:any = {
    messageTemplates: []
  }


  constructor() {
    if(this.bas.envtest) console.log("AppService: ", this.bas.settings?.appId);

    
    let cid = this.bas.ds.findCid();
    if (cid == "bergensportsreiser") this.bss = new BergensportsreiserService(this);

    this.bas.es.ready().then(() => {
      this.onReady();
    })

   }
   
  onReady() {
    let c = this.bas.ds.config.company;
    if(this.bas.envtest) console.log(MiscUtil.getLogText("AppService.onReady: "), c);

    if (c?.ucObj) {
      this.bas.ui.setTitle(c.label + " - " + "Cloud Booking Backoffice");
      if (c.ucObj.customFavicon) {
        this.bas.style.updateFavicon(c.ucObj.customFavicon);
      }
    }
   
  }

}
