import { Injector } from '@angular/core';
import { AppService } from './app.service';
import { HookType } from '@btlib-core';
import { MiscUtil } from '@btlib-core';

export const BSR_PREFIX = {
  ticket: "Billetter: ",
  tikcetGroup: "Billettgruppe: ",
  extra: "Ekstra: ",
  hotel: "Hotell: ",
  hotelGroup: "Hotellgruppe: ",
  trip: "Reise: ",
  additional: "Tillegg: ",
}



export class BergensportsreiserService {

  constructor(public apps: AppService ) {
    if (this.apps.bas.envtest) console.log("BergensportsreiserService.constructor");


    // this.apps.bas.hs.on(
    //   HookType.WebApiRequest, 
    //   (params) => this.onWebApiRequest(params),
    //   50
    // );


    this.apps.bas.hs.on(
      HookType.WebGotoRequest, 
      (params) => this.processGotoRequest(params), 
      50
    );

    
    this.apps.data.messageTemplates = [
      { name: "Billettene er klare", text: "Kjære kunde. Billettene til din bestilte kamp, og info om disse, er nå lastet opp på \"min side\". Vi ønsker god tur, og god kamp. Hilsen Bergen Sportsreiser" },
      { name: "Reisedokumenter er klare", text: "Kjære kunde. Reisedokumenter for din ordre er nå lastet opp på \"min side\" Her kan du blant annet laste ned hotell voucher, se gjerne over at denne samsvarer med din bestilling.\n\nKampbillettene lastes opp på \"min side\" i uken før kampen spilles, og senest 2 dager før kampdag, og vi sender deg en SMS når disse er klare. Se gjerne over at mobilnummer registrert på din ordre er oppdatert.\n\nHar vi billetter for salg til andre kamper som spilles i perioden du er på reise kan du bestille dette på \"min side\".\n\nTa gjerne kontakt om du har noen spørsmål før reisen, også ønsker vi dere en riktig god tur, når den tid kommer.\n\nHa en fin dag!\n\nHilsen Bergen Sportsreiser" },  
    ]

   
  }

  fixNewline(text:any) {
    return text && typeof text === "string" ? text.replace(/\n/g, "<br/>") : text;
  }


  /*
"roomDayRateConfig": {
  "levels": [
    { "level": 1, "daysToArrivalMin": 0, "daysToArrivalMax": 9, "change": 40 },
    { "level": 2, "daysToArrivalMin": 10, "daysToArrivalMax": 21, "change": 25 }
  ],
  "overrides": {
    "liverpool_ibis_styles_dale_street": { "levels": [
    { "level": 1, "daysToArrivalMin": 0, "daysToArrivalMax": 9, "change": 50 },
    { "level": 2, "daysToArrivalMin": 10, "daysToArrivalMax": 21, "change": 30 }
    ] 
    }
   }
},
  */
  findDayRate(roomDayRateConfig:any, arrival:Date|String, hotelPid:string|undefined, dayRate:number, cap:number) {
    let level = this.findDayRateConfig(roomDayRateConfig, arrival, hotelPid);

  
    if (level.change === 0) return dayRate;

    let calc =  dayRate * (1 + (level.change / 100.0));
    // if (this.apps.bas.envtest) console.log("findDayRate, amount: " + amount + " -> " + calc + "; level; ", level);

    // Avrund til nærmeste 100

    calc = Math.floor(calc / 100); // runder ned

    let rest = calc % cap;
    if (rest != 0) {
      calc += (cap - rest);
    }

    let rounded = calc * 100;

    // if (this.apps.bas.envtest) console.log("findDayRate, amount: " + dayRate + ", c: " + level.change + " -> " + calc + ", r: " + rounded + "; level; ", level);

    return rounded;
  }

  findDayRateConfig(roomDayRateConfig:any, arrival:Date|String, hotelPid:string|undefined) {
    let empty = { level: -1,  "daysToArrivalMin": -1, "daysToArrivalMax": -1, change: 0 };

    if (!roomDayRateConfig) return empty;

    if (typeof arrival == "string") {
      let arrDate = MiscUtil.setDateAsString(arrival);
      if (arrDate == null) {
        console.log("arrDate == null, arrival: " + arrival);
        return empty;
      }
      arrival = arrDate;
    }

    let days = this.apps.bas.ui.differenceInDays(arrival as Date, new Date());





    let levels = roomDayRateConfig.levels || [];
    let overrides = roomDayRateConfig.overrides || { };


    if (hotelPid && overrides[hotelPid]) levels = overrides[hotelPid].levels || [];

    
    // if (this.apps.bas.envtest) console.log("findDayRateConfig"
    //   +", arrival: " + arrival 
    //   + ", days; " + days
    //   + ", hotelPid: " + hotelPid 
    //   + ", levels: ", levels
    //   // + ", rdrc.str: " + JSON.stringify(roomDayRateConfig)
    //   // + ", rdrc: ", roomDayRateConfig
    // );

    let level = empty;
    for (let l of levels) {
      if (l.daysToArrivalMin <= days && days <= l.daysToArrivalMax ) {
        level = l;
        break;
      }
    }

    if (level.change === undefined) {
      console.log("level.change === undefined, level: ", level);
      level = empty; 
    }

    return level;
  }

   // https://192.168.1.81:8202/api/opplevkragero/lib/goto?goto-action=order&goto-id=AFQ002
  // https://192.168.1.81:8202/api/bergensportsreiser/lib/goto?goto-action=order&goto-id=AFN094
  processGotoRequest(params:any) {
    if(this.apps.bas.envtest) console.log("GotoPage, processRequest: ", params);
    

    return new Promise<boolean>((res, rej) => {

      let qps = params.queryParams;

      let action = qps["goto-action"];
      let id = qps["goto-id"];
      
      let target = "";
      let queryParams:any = { };

      for (let pn in qps) {
        if (pn.startsWith("goto-")) continue;

        queryParams[pn] = qps[pn];
      }

      let appid = this.apps.bas.settings.appId;

      if (action) {
        if (action == "order") {

          if (appid == "cbadmin") {
            target = this.apps.bas.ui.getRouterPrefix() +  "/app/bergensportsreiser/order";
            queryParams["id"] = id;
            // delete queryParams.id;
          }

        }

      }



      if(this.apps.bas.envtest) console.log("target: " + target + ", queryParams: ", queryParams);
      

      if (target) {
        this.apps.bas.ui.router.navigate(
          [ target ], 
          { queryParams: queryParams, replaceUrl: true }
        );
  
        res(false);
      }


      res(true); // går til neste
    });
  }



}
